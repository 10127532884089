import { ThemeProvider } from '@mui/material/styles';
import { theme } from './assets/styles/theme';
import NavBar from "./components/NavBar"
import Footer from "./components/Footer"
import SignUp from "./components/AuthRegister";
import SignIn from "./components/AuthLogin";
import { Route, Routes } from 'react-router-dom';
import HomePage from "./components/HomePage";
import InvestorForm from './components/InvestorForm';
import OptimalPortfolio from './components/OptimalPortfolio';
import Dashboard from './components/Dashboard';
import InvestorProfile from './components/InvestorProfile';
import AboutUs from './components/AboutUs';
import Learning from './components/Learning';



function App() {
  return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar/>
          <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/learning" element={<Learning />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/questionnaire" element={<InvestorForm />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/investor-profile" element={<InvestorProfile />} />
          <Route path="/dashboard/optimal-portfolio" element={<OptimalPortfolio />} />
          </Routes>
          <Footer/>
        </ThemeProvider>
      </div>

  );
}

export default App;
