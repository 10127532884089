import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, CircularProgress, Box, Link } from '@mui/material';

function PortfolioDescriptions() {
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [additionalDataFetched, setAdditionalDataFetched] = useState(false);
  const [descriptions, setDescriptions] = useState([]);

  useEffect(() => {
    const storedPortfolioData = JSON.parse(localStorage.getItem('PortfolioData'));
    if (storedPortfolioData) {
      setPortfolioData(storedPortfolioData);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!additionalDataFetched && portfolioData.length > 0) {
      const fetchAdditionalData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('https://sltresearch.pythonanywhere.com/api/etf-characteristics', {
            headers: { Authorization: `Token ${token}` },
          });

          const etfCharacteristicsData = response.data;
          const updatedDescriptions = portfolioData.map((item) => {
            const { ticker, weight } = item;
            const etfCharacteristics = etfCharacteristicsData.find(etf => etf.ticker === ticker.toUpperCase());
            if (etfCharacteristics) {
              const formattedWeight = (weight * 100).toFixed(2) + '%';
              return {
                description: `${ticker.toUpperCase()} (${formattedWeight}), ${etfCharacteristics.description}`,
                link: etfCharacteristics.link,
              };
            }
            return '';
          });

          setDescriptions(updatedDescriptions);
          setAdditionalDataFetched(true);
        } catch (error) {
          console.error('Error fetching ETF characteristics', error);
        }

        setLoading(false);
      };

      fetchAdditionalData();
    }
  }, [portfolioData, additionalDataFetched]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <Typography variant="h6">Your Portfolio Explained</Typography>
      {descriptions.map((desc, index) => (
        <Box mt={2} key={index}>
          <Typography variant="body1">
            {desc.description}{' '}
            <Link href={desc.link} target="_blank" rel="noopener">
              (Learn more)
            </Link>
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PortfolioDescriptions;
