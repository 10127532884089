import React from 'react';
import { Paper } from '@mui/material';
import Sidebar from './Dashboard/Sidebar';
import InvestorTable from './Dashboard/InvestorTable';

const InvestorProfile = () => {
  return (
    <div style={{ display: 'flex', width: '100%', marginTop: 10 }}>
      <Sidebar />
      <div style={{ width: '80%', marginLeft: 'auto', marginRight: 10 }}>
        <div className="box">
          <Paper elevation={3} style={{ padding: 16, marginTop: 10, marginBottom: 20 }}>
            <InvestorTable />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default InvestorProfile;
