import React, { useState } from 'react';
import {
  AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, Box
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../assets/images/logo.png";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../assets/styles/NavBar.css'

function Logo(props) {
  const logoSize = props.size || 100; // If size is not provided, default to 100
  const logoPic = <img src={logo} height={logoSize} alt="logo" />;
  return logoPic;
}


export default function NavBar() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  async function handleLogout() {
    try {
      const response = await axios.post(
        'https://sltresearch.pythonanywhere.com/api/logout/',
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response.status === 200) {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('date_joined');
        localStorage.removeItem('PortfolioData');
        navigate('');
      }
    } catch (error) {
      alert("An error occurred while logging out.");
    }
  }

  // Check if a user is logged in
  const isLoggedIn = !!localStorage.getItem('token');

  const links = [
    { text: "About Us", path: "/about" },
    { text: "Learning", path: "/learning" },
    ...(isLoggedIn ? [{ text: "Dashboard", path: "/dashboard" }] : []),
  ];

  return (
    <>
    <AppBar position="static" sx={{ backgroundColor: 'lightgrey', width: '100%' }} style={{ fontFamily: 'Montserrat, sans-serif' }}>
      <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton edge="start" color="inherit" aria-label="logo" component={RouterLink} to="/">
          <Logo size={window.innerWidth <= 768 ? 50 : 100} />
        </IconButton>
        
        {/* Desktop Version */}
        <div className="desktop-menu">
          {links.map(link => (
            <Button color='primary' component={RouterLink} to={link.path}>
              {link.text}
            </Button>
          ))}
        </div>

        <Box flexGrow={1}></Box>

        <div className="desktop-auth">
          {isLoggedIn ? (
            <Button color='primary' variant="outlined" onClick={handleLogout} component={RouterLink} to="/logout">
              Sign Out
            </Button>
          ) : (
            <>
              <Button style={{ marginRight: 10 }} color='primary' variant="contained" component={RouterLink} to="/register">
                Register
              </Button>
              <Button color='primary' variant="outlined" component={RouterLink} to="/login">
                Sign In
              </Button>
            </>
          )}
        </div>
        
        
        {/* Mobile Version */}
        <div className="mobile-menu">
          <IconButton color="inherit" onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>



      {/* Mobile Drawer */}
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          {links.map(link => (
            <ListItem button component={RouterLink} to={link.path} onClick={() => setOpenDrawer(false)}>
              <Typography>{link.text}</Typography>
            </ListItem>
          ))}
          {isLoggedIn ? (
            <ListItem button onClick={handleLogout} component={RouterLink} to="/logout">
              <Typography>Sign Out</Typography>
            </ListItem>
          ) : (
            <>
              <ListItem button component={RouterLink} to="/register" onClick={() => setOpenDrawer(false)}>
                <Typography>Register</Typography>
              </ListItem>
              <ListItem button component={RouterLink} to="/login" onClick={() => setOpenDrawer(false)}>
                <Typography>Sign In</Typography>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </>
  );
}