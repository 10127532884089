import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

function PortfolioMetrics() {
  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('PortfolioData'))[0];
    setMetrics(data);
  }, []);

  return (
    <div>
        <div>
        <Typography variant="h6">Historical Portfolio Metrics</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
        <Typography variant="body1">Average Annual Return: {(metrics.optimal_return * 100).toFixed(2)}%</Typography>
        <Typography variant="body1">Average Annual Volatility: {(metrics.optimal_volatility * 100).toFixed(2)}%</Typography>
        <Typography variant="body1">Sharpe Ratio: {metrics.optimal_sharpe}</Typography>
        <Typography variant="body1">Beta: {metrics.optimal_beta}</Typography>
        </div>
    </div>
  );
}

export default PortfolioMetrics;
