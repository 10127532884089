import React, { useState } from 'react';
import { Paper } from '@mui/material';
import Chart from './Dashboard/Chart';
import PortfolioSummary from './Dashboard/PortfolioSummary';
import Sidebar from './Dashboard/Sidebar';

const Dashboard = () => {
  const [isChartDataLoaded, setIsChartDataLoaded] = useState(false);

  return (
    <div style={{ display: 'flex', width: '100%', marginTop: 10 }}>
      <Sidebar />
      <div style={{ width: '80%', marginLeft: 'auto', marginRight: 10 }}>
        <div className="box">
          <Paper elevation={3} style={{ padding: 16, marginTop: 10 }}>
            <Chart onChartDataLoaded={setIsChartDataLoaded} />
          </Paper>
        </div>
        {isChartDataLoaded && (
          <Paper elevation={3} style={{ padding: 16, marginTop: 10, marginBottom: 20 }}>
            <PortfolioSummary />
          </Paper>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
