import React from 'react';
import EducationBlogs from './Dashboard/Education';
import FAQ from './Dashboard/FAQs';
import { Button } from '@mui/material';

const Learning = () => {
  const isLoggedIn = !!localStorage.getItem('token');

  return (
    <div style={{ minHeight: '100vh' }}>
      <div>
        <EducationBlogs />
      </div>
      <div>
        <FAQ />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, marginBottom: 20 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href={isLoggedIn ? "/dashboard" : "/register"}
        >
          {isLoggedIn ? "Go to Dashboard" : "Start Your Investment Journey"}
        </Button>
      </div>
    </div>
  );
};

export default Learning;
