import React from "react";
import { Box, Button, Container, Typography, Grid } from "@mui/material";
import landingImage from '../assets/images/mobile3.png'; // import your own image

export default function LandingPage() {

  const isLoggedIn = !!localStorage.getItem('token');

  return (
    <Container maxWidth="lg" >
      <Grid container spacing={4} alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" color="textPrimary" gutterBottom>
          Demystifying investing, one click at a time
          </Typography>
          <Typography variant="h6" color="textSecondary" paragraph>
          No complicated jargon or hidden fees, <br/>
          La Maison breaks barriers by including real assets in your tailored investment allocation.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href={isLoggedIn ? "/dashboard" : "/register"}
          >
            {isLoggedIn ? "Go to Dashboard" : "Start Your Investment Journey"}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center">
            <Box component="img" src={landingImage} alt="Landing" sx={{width: '100%', borderRadius: 2}}/>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
