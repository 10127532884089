import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#6d0821',
    },
    secondary: {
      main: '#ffffff',
    },
    tertiary: {
      main: '#8d721e'
    }
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
});
