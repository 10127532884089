import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const timeHorizonOptions = [
  "Less than 1 year",
  "Between 1 and 3 years",
  "Between 3 and 7 years",
  "Between 7 and 10 years",
  "More than 10 years"
];

const financialKnowledgeOptions = [
  "Novice",
  "Intermediate",
  "Expert"
];

const yesNoOptions = [
  "No",
  "Yes"
];

const lossToleranceOptions = [
  "I prefer to avoid any loss, even if it means potentially lower returns",
  "I can tolerate a limited loss up to 10%, if it means potential for higher returns",
  "I am comfortable with potential losses greater than 10% for the possibility of higher returns"
];

const marketReactionOptions = [
  "I actively adjust my portfolio in response to market shifts",
  "I generally maintain my existing investment strategy",
  "I seize the opportunity to invest further during market downturns"
];

const returnImportanceOptions = [
  "Less Important",
  "Equally Important",
  "More Important"
];

const ProfileTable = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('username');
    const url = 'https://sltresearch.pythonanywhere.com/api/questionnaire/?email=' + email;

    axios
      .get(url, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
      });
  }, []);

  if (data === null) return <div>Loading...</div>;

  const getOptionText = (optionArray, index) => {
    if (index >= 0 && index < optionArray.length) {
      return optionArray[index];
    }
    return '';
  };

  const BoldTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
  }));

  const handleButtonClick = () => {
    navigate('/questionnaire');
  };

  return (
    <>
      <Typography variant="h6" marginBottom={3}>Your Investor Profile</Typography>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <BoldTableCell style={{ backgroundColor: '#6d0821', color: 'white' }}>Question</BoldTableCell>
              <BoldTableCell align="right" style={{ backgroundColor: '#6d0821', color: 'white' }}>Answer</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Investment Time Horizon
              </TableCell>
              <TableCell align="right">
                {getOptionText(timeHorizonOptions, data.investment_time_horizon - 1)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Steady Stream of Income
              </TableCell>
              <TableCell align="right">
                {getOptionText(yesNoOptions, data.steadyIncome)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Other Sgnificant Assets / Source of Wealth
              </TableCell>
              <TableCell align="right">
                {getOptionText(yesNoOptions, data.significantAssets)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Significant Liquid Assets
              </TableCell>
              <TableCell align="right">
                {getOptionText(yesNoOptions, data.liquidAssets)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Emergency Fund
              </TableCell>
              <TableCell align="right">
                {getOptionText(yesNoOptions, data.emergencyFund)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Significant Savings Ratio
              </TableCell>
              <TableCell align="right">
                {getOptionText(yesNoOptions, data.savingsRatio)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Adequate Life Insurance In Place
              </TableCell>
              <TableCell align="right">
                {getOptionText(yesNoOptions, data.lifeInsurance)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Loss Tolerance
              </TableCell>
              <TableCell align="right">
                {getOptionText(lossToleranceOptions, data.lossTolerance)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Reaction to Market Fluctuation
              </TableCell>
              <TableCell align="right">
                {getOptionText(marketReactionOptions, data.marketFluctuationReaction - 1)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Financial Knowledge
              </TableCell>
              <TableCell align="right">
                {getOptionText(financialKnowledgeOptions, data.financialKnowledge - 1)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                High Returns Importance compared to Reducing Risk
              </TableCell>
              <TableCell align="right">
                {getOptionText(returnImportanceOptions, data.highReturnImportance)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ marginTop: '20px', fontStyle: 'italic', color: "#6d0821" }} variant="body2">
          Investor profiles change over time, so keep yours updated for a tailored portfolio that matches your risk profile.
        </Typography>
        <Button onClick={handleButtonClick} variant="contained" color="primary" style={{ marginTop: '20px' }}>
          Update Your Investor Profile
        </Button>
      </div>

    </>
  );
};

export default ProfileTable;
