import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PortfolioTable = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [additionalDataFetched, setAdditionalDataFetched] = useState(false);

  useEffect(() => {
    // Retrieve portfolio data from localStorage
    const storedPortfolioData = JSON.parse(localStorage.getItem('PortfolioData'));
    if (storedPortfolioData) {
      setPortfolioData(storedPortfolioData);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!additionalDataFetched && portfolioData.length > 0) {
      const fetchAdditionalData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('https://sltresearch.pythonanywhere.com/api/etf-characteristics', {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
  
          const etfCharacteristicsData = response.data;
  
          const updatedPortfolioData = portfolioData.map((item) => {
            const { ticker } = item;
            const etfCharacteristics = etfCharacteristicsData.find(etf => etf.ticker === ticker.toUpperCase());
            if (etfCharacteristics) {
              return { ...item, ...etfCharacteristics };
            }
            return item;
          });
  
          setPortfolioData(updatedPortfolioData);
          setAdditionalDataFetched(true);
        } catch (error) {
          console.error('Error fetching ETF characteristics', error);
        }
        setLoading(false);
      };
  
      fetchAdditionalData();
    }
  }, [portfolioData, additionalDataFetched]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const BoldTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
  }));

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="portfolio-table">
        <TableHead>
          <TableRow>
            <BoldTableCell align="center" style={{ backgroundColor: '#6d0821', color: 'white' }}>Ticker</BoldTableCell>
            <BoldTableCell align="center" style={{ backgroundColor: '#6d0821', color: 'white' }}>Name</BoldTableCell>
            <BoldTableCell align="center"style={{ backgroundColor: '#6d0821', color: 'white' }}>Asset Class</BoldTableCell>
            <BoldTableCell align="center" style={{ backgroundColor: '#6d0821', color: 'white' }}>Weight</BoldTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {portfolioData.map((item) => {
            const formattedWeight = (item.weight * 100).toFixed(2) + '%'; // format the weight
            return (
              <TableRow key={item.ticker}>
                <TableCell align="center">{item.ticker.toUpperCase()}</TableCell>
                <TableCell align="center">{item.name}</TableCell>
                <TableCell align="center">{item.asset_class}</TableCell>
                <TableCell align="center">{formattedWeight}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PortfolioTable;
