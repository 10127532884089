import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { Typography, CircularProgress } from '@mui/material';
import axios from 'axios';

const Chart = ({ onChartDataLoaded }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('https://sltresearch.pythonanywhere.com/api/optimal-portfolio', {
        headers: { 'Authorization': `Token ${token}` }
      })
      .then((response) => {
        setData(response.data);
        setLoading(false); // Set loading to false after data is fetched
        localStorage.setItem('PortfolioData', JSON.stringify(response.data));
        console.log(localStorage.getItem('PortfolioData'))
        onChartDataLoaded(true); // Signal that chart data has finished loading
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        setLoading(false); // Set loading to false in case of an error too
        onChartDataLoaded(false); // Signal that chart data failed to load
      });
  }, []);

  const formatXAxisLabel = (ticker) => ticker.toUpperCase();

  const formatYAxisTick = (tick) => Math.round(tick * 100);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { ticker, weight } = payload[0].payload;
      const roundedValue = Math.round(weight * 100);
      return (
        <div style={{ backgroundColor: '#fff', padding: 8 }}>
          <p>{`Ticker: ${ticker.toUpperCase()}`}</p>
          <p>{`Weight: ${roundedValue}%`}</p>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h6">Recommended Profile</Typography>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="ticker" tickFormatter={formatXAxisLabel} />
          <YAxis tickFormatter={formatYAxisTick}>
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
              }}
            >
              Weight (%)
            </Label>
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="weight" fill="#6d0821" />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Chart;
