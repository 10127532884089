import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom'; // Importing Link to create a navigable link to the /about page.

export default function Footer() {
    return (
        <Box 
            sx={{
                width: '98%',
                backgroundColor: '#333',
                color: 'white',
                fontFamily: 'Montserrat, Arial, sans-serif',
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Before making any decisions, investors are encouraged to learn more about La Maison 
              and to thoroughly read and understand our 
              <Link to="/about" style={{ textDecoration: 'underline', color: 'white' }}> disclaimer</Link>.<br/>
              La Maison is not a registered investment advisor.
              Investments involve risk and the possible loss of capital. Past performance is not indicative of future
              results. <br/>
              © 2023 La Maison
            </Typography>
        </Box>
    );
}
