import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box } from '@mui/material';

function EducationBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get('https://sltresearch.pythonanywhere.com/api/education/')
      .then(response => {
        setBlogs(response.data);
      })
      .catch(error => console.error(error));
  }, []);

  const handleOpen = (blog) => {
    setSelectedBlog(blog);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedBlog(null);
    setOpen(false);
  };

  return (
    <Box sx={{ width: '80%', margin: '0 auto', textAlign: 'center', marginTop: 10 }}>
      <Typography variant="h5" color="primary" gutterBottom>Resources</Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 5 }}>
        Unlocking your investment potential means ensuring you understand every step of the process,
        and that's our unwavering dedication at La Maison.
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {blogs.map((blog, index) => (
          <Grid item xs={4} key={blog.id}>
            <Paper elevation={3}
              sx={{
                padding: "10px",
                borderRadius: '15px',
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#f5f5f5',
                }
              }}
              onClick={() => handleOpen(blog)}
            >
              <Typography variant="h5" color="primary">{blog.title}</Typography>
              <Typography variant="body2">{blog.teaser}</Typography>
              <Typography variant="caption" color="text.secondary">Click to read more...</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{selectedBlog?.title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
            {selectedBlog?.full_text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EducationBlogs;