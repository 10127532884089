import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, CssBaseline, Grid, Paper, TextField, Typography } from '@mui/material';

export default function SignUp() {
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // Get the form data
    const email = data.get('email');
    // Email validation
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      alert("Invalid email address.");
      return;
    }

    // Continue with the API call if the conditions are met
    try {
      const response = await axios.post('https://sltresearch.pythonanywhere.com/api/register/', {
        username: email,
        password: data.get('password'),
        first_name: data.get('firstName'),
        last_name: data.get('lastName')
      });

      if (response.status === 201) {
        setToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', email);
        localStorage.setItem('first_name', response.data.first_name);
        localStorage.setItem('last_name', response.data.last_name);
        localStorage.setItem('date_joined', response.data.date_joined);
        navigate('/questionnaire');
      }
    } catch (error) {
      // Catch network error or server error
      alert("User Not Created. Please Try Again.");
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: '80vh' }}>
      <CssBaseline />
      <Paper
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
        }}
        elevation={12}
      >
        <Typography component="h1" variant="h5" color="primary">
          Create an Account
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type="email"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Start Your Investment Journey
          </Button>
          <Grid container>
            <Grid item>
              <Button color="primary" href="./login">
                Already have an account? Sign in
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
