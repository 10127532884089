import React, { useState, useRef } from 'react';
import { Box, Typography, IconButton, Paper, Avatar, Button } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

import sidney from '../assets/images/sidney.png';

function AboutUs() {

  const isLoggedIn = !!localStorage.getItem('token');

  const paragraphs = [
    'Welcome to La Maison, where we believe in the power of combining real assets and traditional investments to create a truly diversified portfolio.',
    'With our digital platform designed to simplify investing, you can access real estate, art, and other tangible assets alongside traditional stocks and bonds, allowing you to build a well-rounded investment portfolio.',
    "Whether you're a novice or a seasoned investor, our technology makes it easier than ever to grow your wealth. We use advanced algorithms to design a personalized portfolio that aligns with your risk profile and the time horizon of your financial goals.",
    'With personalized portfolios, low minimum investment, and an extensive library of educational resources, La Maison is here to guide you towards financial success with confidence and ease.',
    'Our mission is to democratize access to diversified investment portfolios, empowering every investor with confidence and ease. Leveraging advanced technology, we aim to simplify investing in a range of assets, regardless of experience level.',
    'Our values are: Simplicity, Transparency, Accessibility'
  ];
  const [stage, setStage] = useState(0);
  const stageRef = useRef(stage);
  stageRef.current = stage;

  const handleNext = () => {
    setStage((prevStage) => Math.min(prevStage + 1, paragraphs.length - 1));
  };

  const handleBack = () => {
    setStage((prevStage) => Math.max(prevStage - 1, 0));
  };

  const founder = {
    img: sidney,
    name: 'Sidney',
    bio: [
      "Passionate advocate for unlocking the world of investment to everyone. Sidney spent years immersed in asset management, and always been fascinated by the power of innovative technology.", <br />,
      "His goal? To shake up the world of investment by making it simpler, more accessible, and, mainly transparent!", <br />, <br />,
      "He founded La Maison with a clear mission in mind: to break down the barriers between every investor and the kinds of investments usually reserved for the well-heeled—think real estate, art, and more.", <br />,
      "It’s all about giving you the keys to a world previously kept under lock and key by a wealthy few. Sidney believes that whether you have thousands or just a few hundred bucks to invest, you deserve a shot at the big leagues."
    ]
  };
  
  return (
    <Box style={{ minHeight: '100vh' }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
      <Typography variant="h5" gutterBottom component="div" sx={{ color: '#6d0821', marginBottom: 5 }}>
        About La Maison
      </Typography>
      <Paper sx={{ p: 2, width: '80%', maxWidth: '600px', minHeight: '150px', position: 'relative', overflow: 'hidden', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '3px solid', borderColor: 'primary.main' }}>
        <Typography variant="body1" paragraph sx={{ color: '#616161', whiteSpace: 'pre-line', textAlign: 'center' }}>
          {paragraphs[stageRef.current]}
        </Typography>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
        <IconButton color="primary" onClick={handleBack} disabled={stage === 0}>
          <ArrowBack />
        </IconButton>
        <Typography>{`${stage + 1}/${paragraphs.length}`}</Typography>
        <IconButton color="primary" onClick={handleNext} disabled={stage === paragraphs.length - 1}>
          <ArrowForward />
        </IconButton>
      </Box>
      <Typography variant="h5" gutterBottom component="div" sx={{ color: '#6d0821', marginBottom: 10, marginTop: 5 }}>
        About The Person Behind La Maison
      </Typography>
      <Box sx={{ marginBottom: 5, display: 'flex', justifyContent: 'center', width: '80%', maxWidth: '900px' }}>
        <Box sx={{ textAlign: 'center', flex: '1 1 400px', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar alt={founder.name} src={founder.img} sx={{ width: 120, height: 120, marginBottom: '15px' }} />
          <Typography variant="h6" sx={{ marginBottom: '10px' }}>{founder.name}</Typography>
          <Typography variant="body1" sx={{ maxWidth: '900px', mx: 'auto' }}>{founder.bio}</Typography>
        </Box>
      </Box>

      {/* Adding Disclaimer Section */}
      <Box sx={{ width: '80%', maxWidth: '900px', textAlign: 'center', marginTop: 5, marginBottom: 5, p: 2, border: '1px solid #ccc', borderRadius: '12px', backgroundColor: '#f9f9f9' }}>
        <Typography variant="h5" gutterBottom component="div" sx={{ color: '#6d0821' }}>
          Disclaimer
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>La Maison</strong> ("we", "our", or "us") offers a robo-advisor platform meant to deliver automated investment recommendations. The content provided by <strong>La Maison</strong> does not constitute financial advice, legal advice, tax advice, or any other professional advice, and should not be relied upon for making investment or financial decisions. All investments involve risk, including the loss of principal, and there is no guarantee that investment strategies will be successful.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>No Regulatory Endorsement:</strong> <strong>La Maison</strong> is not a registered investment advisor, broker/dealer, financial analyst, financial bank, securities broker, or financial planner. We are not registered with any financial or securities regulatory authority, and we do not claim to be. Thus, we are not subject to the regulations and requirements of any financial regulatory body.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>No Personalized Advice:</strong> The information and advice provided by our robo-advisor are based on your input and predefined algorithms, not on a complete personal financial analysis. While we have confidence in our algorithm and its generated advice, we strongly encourage investors to corroborate our insights with other investment professionals or conduct additional research when making financial decisions.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>Risks and Limitations:</strong> Investing in securities involves risks, and there is always the potential of losing money when you invest in securities. The past performance of a security, or financial product does not guarantee future results or returns. Before investing, consider your investment objectives, level of experience, financial situation, risk tolerance, and investment horizon.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>Market Information:</strong> Any information, news, research, analyses, prices, or other information contained on this website are provided as general market commentary, and do not constitute investment advice. <strong>La Maison</strong> will not accept liability for any loss or damage, including without limitation to, any loss of profit, which may arise directly or indirectly from use of or reliance on such information.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>Third-Party Links:</strong> This website may contain links to third-party websites or services that are not owned or controlled by <strong>La Maison</strong>. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>Indemnification:</strong> By using our services, you agree to indemnify and hold harmless <strong>La Maison</strong>, its officers, employees, and affiliates against any and all claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the services and our content.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#616161', textAlign: 'justify' }}>
          <strong>User Acknowledgment:</strong> By using the services provided by <strong>La Maison</strong>, you acknowledge that you have read, understood, and agreed to this disclaimer, and that you are solely responsible for all decisions and actions you take based on the information provided by our services.
        </Typography>
        {/* ... (repeat the above line with different paragraphs of the disclaimer) */}
      </Box>

      <Button
        variant="contained"
        color="primary"
        size="large"
        href={isLoggedIn ? "/dashboard" : "/register"}
        sx={{ marginBottom: '20px' }} // Adds some space between the button and the footer
      >
        {isLoggedIn ? "Go to Dashboard" : "Start Your Investment Journey"}
      </Button>
    </Box>
  );
}

export default AboutUs;