import React, { useState } from 'react';
import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Paper, Typography } from '@mui/material';

export default function InvestorForm2({ onNext, onPrev }) {
    const [formValues, setFormValues] = useState({
        steadyIncome: 0,
        significantAssets: 0,
        liquidAssets: 0,
        emergencyFund: 0,
        savingsRatio: 0,
        lifeInsurance: 0,
    });

    const handleChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.checked ? 1 : 0
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Pass the updated form values to the onNext function
        onNext(formValues);
    };


    return (
        <Container component="main" maxWidth="xs" style={{ minHeight: '100vh' }}
        >
            <CssBaseline />
            <Paper
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                    width: 500,
                }}
                elevation={12}
            >
                <Typography component="h1" variant="h5" color="primary">
                    What Is Your Risk Capacity?
                </Typography>
                 <Typography variant="body1" color="primary" style={{fontStyle: 'italic'}}>
                    Select all that apply
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%', mt: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValues.steadyIncome}
                                onChange={handleChange}
                                name="steadyIncome"
                                color="primary"
                            />
                        }
                        label="I have a steady stream of income from a stable job or other source."
                        sx={{ mt: 2 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValues.significantAssets}
                                onChange={handleChange}
                                name="significantAssets"
                                color="primary"
                            />
                        }
                        label="I have other significant financial assets or sources of wealth."
                        sx={{ mt: 2 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValues.liquidAssets}
                                onChange={handleChange}
                                name="liquidAssets"
                                color="primary"
                            />
                        }
                        label="I have a significant amount of liquid assets, such as cash or highly liquid investments."
                        sx={{ mt: 2 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValues.emergencyFund}
                                onChange={handleChange}
                                name="emergencyFund"
                                color="primary"
                            />
                        }
                        label="I have a significant emergency fund or savings buffer."
                        sx={{ mt: 2 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValues.savingsRatio}
                                onChange={handleChange}
                                name="savingsRatio"
                                color="primary"
                            />
                        }
                        label="I have a sufficient savings ratio."
                        sx={{ mt: 2 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValues.lifeInsurance}
                                onChange={handleChange}
                                name="lifeInsurance"
                                color="primary"
                            />
                        }
                        label="I have an adequate life insurance in place and funded."
                        sx={{ mt: 2 }}
                    />
                    <Button onClick={onPrev} variant="contained" color="secondary" fullWidth sx={{ mt: 3, mb: 0 }}>
                        Back
                    </Button>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                        Next
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}
