import React, { useEffect, useState }  from 'react';
import { Paper, Box, Typography, Link, List, ListItemText, ListItemButton } from '@mui/material';
import axios from 'axios';

const Sidebar = () => {
    const firstName = localStorage.getItem('first_name');
    const lastName = localStorage.getItem('last_name');
    const dateJoined = new Date(localStorage.getItem('date_joined')).toLocaleDateString();

    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const email = localStorage.getItem('username');
        const url = 'https://sltresearch.pythonanywhere.com/api/questionnaire/?email='+email;
        axios.get(url, {
            headers: { 'Authorization': `Token ${token}` }
        })
        
        .then((response) => {
            setProfile(response.data);
            console.log(profile);
        })
        .catch((error) => {
            console.error('Error fetching the risk profile', error);
        });
    }, []);

    const calculateRiskProfile = (targetBeta) => {
      if (targetBeta < 0.5) {
          return "Conservative";
      } else if (targetBeta >= 0.5 && targetBeta < 1) {
          return "Balanced";
      } else if (targetBeta >= 1 && targetBeta < 1.5) {
          return "Dynamic";
      } else {
          return "Aggressive";
      }
  };

  const riskProfile = profile ? calculateRiskProfile(profile.target_beta) : null;

  return (
      <div style={{ width: '20%', background: 'white', height: '100vh', padding: '10px' }}>
          <Paper style={{ padding: 3 }}>
              <Box margin={2}>
                  <Typography variant="h6">Welcome back {firstName}</Typography>
                  <Typography variant="body2">Thanks for being loyal to La Maison since {dateJoined}</Typography>
              </Box>
              <Box margin={2}>
                  <Typography variant="h6">Risk Profile</Typography>
                  {riskProfile && <Typography variant="body2">{`Calculated Risk Profile: ${riskProfile}`}</Typography>}
              </Box>
          </Paper>
        <Box mt={2}>
          <List>
          <ListItemButton component={Link} href="/dashboard" sx={{
    '&:hover': {
      backgroundColor: '#6d0821',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff',
      },
    },
  }}>
              <ListItemText primary="Summary" />
            </ListItemButton>
            <ListItemButton component={Link} href="/dashboard/investor-profile" sx={{
    '&:hover': {
      backgroundColor: '#6d0821',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff',
      },
    },
  }}>
              <ListItemText primary="Investor Profile" />
            </ListItemButton>
            <ListItemButton component={Link} href="/dashboard/optimal-portfolio" sx={{
    '&:hover': {
      backgroundColor: '#6d0821',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff',
      },
    },
  }}>
              <ListItemText primary="Optimal Portfolio Details" />
            </ListItemButton>
            
              
            
            
          </List>
        </Box>
      </div>
    );
};

export default Sidebar;
