import React, { useState } from 'react';
import { TextField, Button, Container, CssBaseline, Paper, Typography, Box } from '@mui/material';

const timeHorizonOptions = [
    "Less than 1 year",
    "Between 1 and 3 years",
    "Between 3 and 7 years",
    "Between 7 and 10 years",
    "More than 10 years"
];

export default function InvestorForm1({ onNext }) {
    const [investmentTimeHorizon, setInvestmentTimeHorizon] = useState("");

    const handleChange = (event) => {
        setInvestmentTimeHorizon(parseInt(event.target.value, 10));;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Pass the updated form values to the onNext function
        onNext({ investment_time_horizon: investmentTimeHorizon });
    };

    return (
        <Container component="main" maxWidth="xs" style={{ minHeight: '100vh' }}>
            <CssBaseline />
            <Paper
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                    width: 500,
                }}
                elevation={12}
            >
                <Typography component="h1" variant="h5" color="primary">
                    What is your investment time horizon?
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%', mt: 1 }}>
                    <TextField
                        select
                        label="Select your time horizon"
                        value={investmentTimeHorizon}
                        onChange={handleChange}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        <option value="" disabled>
                        </option>
                        {timeHorizonOptions.map((option, index) => (
                            <option key={index} value={index + 1}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!investmentTimeHorizon}
                    >
                        Next
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}
