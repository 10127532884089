import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, Label } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Typography, CircularProgress, Box } from '@mui/material';

function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const date = new Date(payload[0].payload.date);
    const formattedDate = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
    return (
      <div className="custom-tooltip" style={{ background: '#f5f5f5', padding: '10px', border: '1px solid #ccc' }}>
        <p className="label">{`Date: ${formattedDate}`}</p>
        <p className="label">{`Value: $${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }

  return null;
}

function PortfolioChart() {
  const [data, setData] = useState(null);
  const [infoData, setInfoData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      const portfolioData = JSON.parse(localStorage.getItem('PortfolioData'));
      const token = localStorage.getItem('token');
      const response = await axios.post('https://sltresearch.pythonanywhere.com/api/portfolio-value/', portfolioData, {
        headers: { 'Authorization': `Token ${token}` }
      });
      const chartData = response.data.dates.map((date, i) => ({
        date,
        value: response.data.values[i]
      }));
      setData(chartData);
      setInfoData({
        total_return_value: response.data.total_return_value,
        total_return_percentage: response.data.total_return_percentage,
        max_drawdown_value: response.data.max_drawdown_value,
        max_drawdown_percentage: response.data.max_drawdown_percentage,
        period: response.data.period,
      });
    };
    fetchData();
  }, []);


  if (!data || !infoData) return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
      <CircularProgress />
    </div>
  );

  return (
    <div>
      <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="start" marginBottom={5}>
        <Typography variant="h6" gutterBottom>Historical Value of a $1,000 Investment in the Recommended Portfolio</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="body2">
            Period: {infoData.period} years
          </Typography>
          <Typography variant="body2">
            Total Return: ${infoData.total_return_value.toFixed(2) + " / " + (infoData.total_return_percentage * 100).toFixed(2)}%
          </Typography>
          <Typography variant="body2">
            Max Drawdown: ${infoData.max_drawdown_value.toFixed(2) + " / " + (infoData.max_drawdown_percentage * 100).toFixed(2)}%
          </Typography>
        </Box>
      </Box>
      <ResponsiveContainer width='100%' height={300}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.palette.tertiary.main} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={theme.palette.tertiary.main} stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="date" tickFormatter={(str) => {
            const date = new Date(str);
            return `${date.getMonth() + 1}/${date.getFullYear()}`;
          }}/>
          <YAxis>
            <Label angle={-90} value='Portfolio Value ($)' position='insideLeft'style={{textAnchor: 'middle'}}/>
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey="value" stroke={theme.palette.primary.main} fillOpacity={1} fill="url(#colorValue)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default PortfolioChart;
