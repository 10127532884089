import React, { useState } from 'react';
import { Box, Button, Container, CssBaseline, Paper, TextField, Typography } from '@mui/material';


const lossToleranceOptions = [
    "I prefer to avoid any loss, even if it means potentially lower returns",
    "I can tolerate a limited loss up to 10%, if it means potential for higher returns",
    "I am comfortable with potential losses greater than 10% for the possibility of higher returns"
];

const marketReactionOptions = [
    "I actively adjust my portfolio in response to market shifts",
    "I generally maintain my existing investment strategy",
    "I seize the opportunity to invest further during market downturns"
];

const financialKnowledgeOptions = [
    "Novice",
    "Intermediate",
    "Expert"
];

const returnImportanceOptions = [
    "Less Important",
    "Equally Important",
    "More Important"
];

export default function InvestorForm3({ onNext, onPrev }) {
    const [formValues, setFormValues] = useState({
        lossTolerance: "",
        marketFluctuationReaction: "",
        financialKnowledge: "",
        highReturnImportance: ""
    });

    const handleChange = (event) => {
        let numberValue;
        if (event.target.value === "") {
            numberValue = 0;
        } else {
            numberValue = Number(event.target.value);
        }
        console.log(`Converted value for ${event.target.name}:`, numberValue);

        setFormValues({
            ...formValues,
            [event.target.name]: numberValue
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Pass the updated form values to the onNext function
        onNext(formValues);
    };

    return (
        <Container component="main" maxWidth="xs" style={{ minHeight: '100vh' }}>
            <CssBaseline />
            <Paper
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                    width: 680,
                }}
                elevation={12}
            >
                <Typography component="h1" variant="h5" color="primary">
                    What Is Your Risk Tolerance?
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%', mt: 1 }}>
                    <TextField
                        select
                        label="How much loss can you tolerate before feeling uncomfortable?"
                        value={formValues.lossTolerance}
                        onChange={handleChange}
                        name="lossTolerance"
                        SelectProps={{ native: true }}
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        <option value="" disabled>
                        </option>
                        {lossToleranceOptions.map((option, index) => (
                            <option key={index} value={index}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="How do you react to market fluctuations?"
                        value={formValues.marketFluctuationReaction}
                        onChange={handleChange}
                        name="marketFluctuationReaction"
                        SelectProps={{ native: true }}
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 1 }}
                    ><option value="" disabled>

                        </option>
                        {marketReactionOptions.map((option, index) => (
                            <option key={index} value={index + 1}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="What is your financial knowledge and understanding of investments?"
                        value={formValues.financialKnowledge}
                        onChange={handleChange}
                        name="financialKnowledge"
                        SelectProps={{ native: true }}
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 1 }}
                    ><option value="" disabled>

                        </option>
                        {financialKnowledgeOptions.map((option, index) => (
                            <option key={index} value={index + 1}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="How important is achieving high returns to you compared to reducing risk?"
                        value={formValues.highReturnImportance}
                        onChange={handleChange}
                        name="highReturnImportance"
                        SelectProps={{ native: true }}
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 1 }}
                    ><option value="" disabled>

                        </option>
                        {returnImportanceOptions.map((option, index) => (
                            <option key={index} value={index}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <Button onClick={onPrev} variant="contained" color="secondary" fullWidth sx={{ mt: 3, mb: 0 }}>
                        Back
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={
                            formValues.lossTolerance === "" ||
                            formValues.marketFluctuationReaction === "" ||
                            formValues.financialKnowledge === "" ||
                            formValues.highReturnImportance === ""
                        }
                    >
                        Submit Your Investor Profile
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}
