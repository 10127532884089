import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
    {
        question: 'How does your algorithm-based financial advisory service work?',
        answer: 'Our algorithm analyzes your risk profile to generate a personalized financial portfolio. <br> It considers various factors, such as income, wealth, risk tolerance, and time horizon, to recommend suitable investment strategies.',
    },
    {
        question: 'Is algorithm-based financial advice as effective as human-led advisory services?',
        answer: 'Yes, algorithm-based financial advice can be highly effective and efficient. <br> Our algorithm incorporates sophisticated methodologies and data analysis to provide well-informed investment recommendations tailored to your specific needs.',
    },
    {
        question: 'How do I determine my risk profile for the algorithm to analyze?',
        answer: 'During the onboarding process, you will complete a risk assessment questionnaire. <br> Based on your responses, our algorithm will identify your risk tolerance level and use this information to generate appropriate investment options.',
    },
    {
        question: 'Are there any limitations to algorithm-based financial advice?',
        answer: 'While our algorithm is highly sophisticated, it\'s essential to understand that it operates based on available data and historical patterns. <br> It may not account for unforeseen events or extreme market fluctuations. <br> Past performance is not indicative of future results.',
    },
    {
        question: 'How can I access the algorithm-generated financial advice?',
        answer: 'Accessing our algorithm-based financial advice is simple. <br> Just create an account on our platform, complete the risk assessment questionnaire, and our algorithm will generate personalized investment recommendations for you.',
    },
    {
        question: 'Can I customize the algorithm\'s recommendations according to my preferences?',
        answer: 'Our algorithm takes your preferences into account during the risk assessment process. <br> While it primarily relies on data-driven insights, you have the flexibility to adjust your risk profile or investment preferences as needed.',
    },
    {
        question: 'What if I have additional questions to better understand the algorithm-generated recommendations?',
        answer: 'We understand the importance of understanding in financial decisions. <br> An AI chatbot will soon be available to assist you during your investment journey making sure you have a full understanding of the proposed allocation and the rationale for each ETFs we use.',
    },
];

const FAQ = () => (
    <Box sx={{ width: '80%', margin: '0 auto', textAlign: 'center', marginTop: 10 }}>
        <Typography variant="h5" color="primary" gutterBottom sx={{ marginBottom: 5 }}>
            Frequently Asked Questions
        </Typography>
        {faqs.map((faq, index) => (
            <Accordion key={index}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                >
                    <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="primary" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </AccordionDetails>
            </Accordion>
        ))}
    </Box>
);

export default FAQ;