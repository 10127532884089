import React, { useState } from "react";
import InvestorForm1 from "./InvestorForm1";
import InvestorForm2 from "./InvestorForm2";
import InvestorForm3 from "./InvestorForm3";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export default function InvestorForm() {
    
  // Set initial state for formValues
  const [formValues, setFormValues] = useState({
    email: localStorage.getItem('username') || '',
    investment_time_horizon: 0,
    steadyIncome: 0,
    significantAssets: 0,
    liquidAssets: 0,
    emergencyFund: 0,
    savingsRatio: 0,
    lifeInsurance: 0,
    lossTolerance: 0,
    marketFluctuationReaction: 0,
    financialKnowledge: 0,
    highReturnImportance: 0
  });

  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  const handleFormChange = (newFormValues) => {
    const updatedFormValues = { ...formValues, ...newFormValues };
  
    setFormValues(updatedFormValues);
  
    if (step === 3) {
      handleFormSubmit(updatedFormValues);
    } else {
      handleStepChange(step + 1);
    }
  };
  
  const handleFormSubmit = async (currentFormValues) => {
    try {
      console.log(currentFormValues);
      const response = await axios.post(
        'https://sltresearch.pythonanywhere.com/api/questionnaire/',
        currentFormValues
      );
      console.log(response);
      if (response.status === 201) {
        navigate('/dashboard');
      }
    } catch (error) {
      alert("An error occurred while submitting the form.");
    }
  };
  

  return (
    <div>
      {step === 1 && (
        <InvestorForm1
          onNext={handleFormChange}
          onPrev={() => handleStepChange(step - 1)}
        />
      )}
      {step === 2 && (
        <InvestorForm2
          onNext={handleFormChange}
          onPrev={() => handleStepChange(step - 1)}
        />
      )}
      {step === 3 && (
        <InvestorForm3
          onNext={handleFormChange}
          onPrev={() => handleStepChange(step - 1)}
        />
      )}  
    </div>
  );
}
